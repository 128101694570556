import * as React from 'react'
import Card from './Card'
import Carousel from '../sliders/Carousel'
import { StaticImage } from 'gatsby-plugin-image'

const OmegaCategoryBtns = () => {
  return (
    <section>
      <h1 className="text-center py-20 px-5">
        EXPLORE MORE GREAT OMEGA STORIES FROM GRAY &amp; SONS
      </h1>
      <Carousel
        slides={{ xs: 1, md: 2, xl: 3 }}
        infiniteLoop={false}
        className={'py-8 mx-12'}
        gap={16}
        scrollStep={3}
      >
        <Card
          className="card"
          name="Omega Seamaster"
          url={'/preowned/used-omega-seamaster-for-sale'}
        >
          <StaticImage
            src="../../images/preowned/used-omega-seamaster-article-btn.png"
            alt="Pre-Owned Certified Used Omega Seamaster"
            aria-label="Omega Seamaster Page"
          />
        </Card>
        <Card
          className="card"
          name="Omega Speedmaster"
          url={'/preowned/used-omega-speedmaster-for-sale'}
        >
          <StaticImage
            src="../../images/preowned/used-omega-speedmaster-article-btn.png"
            alt="Pre-Owned Certified Used Omega Speedmaster"
            aria-label="Omega Speedmaster Page"
          />
        </Card>
        <Card
          className="card"
          name="Omega Constellation"
          url={'/preowned/used-omega-constellation-for-sale'}
        >
          <StaticImage
            src="../../images/preowned/used-omega-constellation-article-btn.png"
            alt="Pre-Owned Certified Used Omega Constellation"
            aria-label="Omega Constellation Page"
          />
        </Card>
        <Card
          className="card"
          name="Omega De Ville"
          url={'/preowned/used-omega-deville-for-sale'}
        >
          <StaticImage
            src="../../images/preowned/used-omega-deville-article-btn.png"
            alt="Pre-Owned Certified Used Omega Deville"
            aria-label="Omega De Ville Page"
          />
        </Card>

      </Carousel>
    </section>
  )
}
export default OmegaCategoryBtns
