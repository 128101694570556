import * as React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const Card = ({ name, url, children }) => {
  return (
    <Link
      className="group flex-col relative"
      to={url}
    >
      <div className="w-full flex justify-center align-middle">
        <div className="z-50 h-full w-full flex items-center absolute">
          <div className="w-full text-center opacity-0 group-hover:opacity-100 ease-out duration-500">
            {name.toUpperCase()}
          </div>
        </div>
        <div className="z-0 transform duration-500 opacity-100 relative md:group-hover:opacity-25 group-hover:blur-sm">
          {children}
        </div>
      </div>
      <div className="flex justify-center text-black p-10 bg-white md:hidden">
        {name.toUpperCase()}
      </div>
    </Link>
  )
}
Card.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Card
